import Flex from "app/components/Flex";
import { CenterWrapper, Container } from "app/components/RouteComponents";
import ScrollToTopOnMount from "app/components/ScrollToTopOnMount";
import React from "react";
import { Helmet } from "react-helmet-async";
import { Redirect } from "react-router";
import { SavedClasses } from "./SavedClasses";
import { SavedPrograms } from "./SavedPrograms";
import { useGetMenuOption } from "./hooks";
import { Menu } from "./Menu";
import { Title } from "./styles";

const Tabs = {
  classes: SavedClasses,
  programs: SavedPrograms,
};

const Saved = () => {
  const { selectedOption, isValidMenuOption } = useGetMenuOption();

  if (!isValidMenuOption) {
    return <Redirect to="/not-found" />;
  }

  const Body = Tabs[selectedOption];

  return (
    <CenterWrapper>
      <Helmet title="Saved | Online Dance Classes and Tutorials" />
      <ScrollToTopOnMount />
      <Container>
        <Title pb={[4]}>Saved</Title>
        <Flex justifyContent="space-between" mb={[4]}>
          <Flex width={["100%", "100%", "75%", "50%"]} flexWrap="wrap">
            <Menu />
          </Flex>
        </Flex>
        <Body />
      </Container>
    </CenterWrapper>
  );
};

export default Saved;

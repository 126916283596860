import ProgramCardList from "app/components/ProgramCardList";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import ProgramCard from "app/components/ProgramCard";
import React from "react";
import { Link } from "react-router-dom";
import { Waypoint } from "app/components/Waypoint";
import { ProgramV2 } from "services/graphql";
import { useGetSavedPrograms } from "./hooks";

export function SavedPrograms() {
  const {
    isFetchingInitialSavedPrograms,
    savedPrograms,
    loadingSavedPrograms,
    fetchMoreSavedPrograms,
    pageInfoSavedPrograms,
  } = useGetSavedPrograms();

  if (isFetchingInitialSavedPrograms) {
    return <LoaderCentered />;
  }

  return savedPrograms.length ? (
    <ProgramCardList
      mt={2}
      cards={savedPrograms}
      sentinel={
        !loadingSavedPrograms && (
          <Waypoint
            hasMore={pageInfoSavedPrograms.hasNextPage}
            fetchData={fetchMoreSavedPrograms}
          />
        )
      }
      renderCard={({ cardData }: { cardData: ProgramV2 }) => (
        <ProgramCard
          key={cardData.slug}
          data={cardData}
          selectedFrom="SavedPrograms"
        />
      )}
    />
  ) : (
    <span>
      You don&apos;t have any saved programs. Search for{" "}
      <Link to="/programs">programs</Link> and click the heart icon to save
      them!
    </span>
  );
}

/* eslint-disable consistent-return */
import Button from "app/components/Button";
import HeartIconButton from "app/components/Button/IconButton/HeartIconButton";
import VolumeIconButton from "app/components/Button/IconButton/VolumeIconButton";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import Icon, {
  CheckSquareV2,
  Classes,
  Lock,
  LockedIndicatorBlack,
} from "app/components/Icon";
import ProgressBar from "app/components/ProgressBar";
import ResponsiveVideoPlayer from "app/components/ResponsiveVideoPlayer";
import Text from "app/components/Text";
import Thumbnail from "app/components/Thumbnail";
import { H7 } from "app/components/Typography";
import { IS_MOBILE, VIDEO_RATIO } from "constants/index";
import useOnClickOutside from "hooks/useOnClickOutside";
import useOnScreen from "hooks/useOnScreen";
import { toggleSettingsVolumeAction } from "modules/components";
import { userPreviewingContentAction } from "modules/content";
import { useComponentsSettingsVolume } from "modules/selectors/components";
import { useContentPreviewingProgramSlug } from "modules/selectors/content";
import { useUserSubscription } from "modules/selectors/users";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { flexbox, grid, size, space } from "styled-system";
import transition from "styled-transition-group";
import {
  ClassCountLabel,
  ClassTypeLabel,
  OverlayShadow,
  ThumbnailWrapper,
  Title,
  VideoDetails,
} from "./components";

const CARD_HEIGHT = 368;
const VIDEO_WIDTH = CARD_HEIGHT * VIDEO_RATIO;
const CARD_PREVIEW_PADDING_RIGHT = `${(1 - 1 / VIDEO_RATIO) * 375}%`;

export const Wrapper = styled.a`
  position: relative;
  width: 280px;
  height: 368px;
  cursor: pointer;

  ${space};
  ${flexbox};
  ${grid};
  ${size};
`;

export const Container = styled.div`
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const FadeOutThumbnail = transition.div`
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    padding-right: 175%;

    &:exit {
        opacity: 1;
    }
    &:exit-active {
        opacity: 0.01;
        transition: opacity 500ms ease-in;
    }
`;

const LockOverlay = styled.div`
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  top: 0;
`;

const StyledProgressBar = styled(ProgressBar)`
  position: absolute;
  bottom: 0;
  height: 6px;
  width: 100%;

  ${({ hide }) => hide && "opacity: 0"};
`;

let mouseEnterTimeout = null;

export const Card = ({
  contentData,
  goToContent,
  isProgressCompleted,
  isSaved,
  onClick,
  onMouseEnter,
  saveUserContent,
  selectedFrom,
  totalClassCount,
  thumbnailPlaceholderSrc,
  thumbnailSrc,
  disableClick,
}) => {
  const dispatch = useDispatch();
  const wrapperRef = useRef();
  const containerRef = useRef();
  const [isMoreDetailsShowing, toggleMoreDetailsShowing] = useState(false);
  const [isPreviewVideoShowing, togglePreviewVideoShowing] = useState(false);
  const publishDate = contentData.publish_date || contentData.publishDate;
  const published = moment().isAfter(publishDate) || !publishDate;
  const instructorName =
    contentData.instructor_name || contentData.instructor?.name;
  const previewVideoUrl =
    contentData.preview_url || contentData?.content?.assets?.previewVideoURL;
  const onScreen = useOnScreen(wrapperRef, "-100px");
  const { isSubscriptionActive } = useUserSubscription();
  const settingsVolume = useComponentsSettingsVolume();
  const previewingProgramSlug = useContentPreviewingProgramSlug();
  const progressPercent =
    (contentData.completed_classes / totalClassCount) * 100;

  useOnClickOutside(wrapperRef, () => {
    if (IS_MOBILE && isMoreDetailsShowing) {
      toggleMoreDetails(false);
    }
  });

  const handleCardClick = e => {
    e.preventDefault();

    if (disableClick) {
      return;
    }
    if (onClick) {
      return onClick();
    }
    if (IS_MOBILE) {
      const toggle = !isPreviewVideoShowing;
      toggleMoreDetails(toggle);
    } else {
      goToContent(e);
    }
  };

  const handleButtonClick = e => {
    e.preventDefault();

    if (disableClick) {
      return;
    }
    if (onClick) {
      return onClick();
    }
    goToContent(e);
  };

  const handleMouseEnter = () => {
    if (onMouseEnter) {
      return onMouseEnter();
    }
    if (IS_MOBILE || !published) {
      return;
    }
    if (!isMoreDetailsShowing) {
      mouseEnterTimeout = setTimeout(() => {
        toggleMoreDetails(true);
      }, 200);
    }
  };

  const handleMouseLeave = () => {
    if (IS_MOBILE || !published) {
      return;
    }

    toggleMoreDetails(false);
    clearTimeout(mouseEnterTimeout);
  };

  const toggleMoreDetails = useCallback(
    isToggled => {
      if (!previewingProgramSlug && isToggled) {
        toggleMoreDetailsShowing(true);
        togglePreviewVideoShowing(true);
      } else {
        toggleMoreDetailsShowing(false);
        togglePreviewVideoShowing(false);
      }
    },
    [previewingProgramSlug]
  );

  useEffect(
    function onAppearOnScreen() {
      if (!onScreen && isPreviewVideoShowing) {
        toggleMoreDetails(false);
      }
    },
    [isPreviewVideoShowing, onScreen, toggleMoreDetails]
  );

  return (
    <Wrapper
      href={`/programs/${contentData.slug}`}
      ref={wrapperRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleCardClick}
      active={isMoreDetailsShowing}
    >
      <Container ref={containerRef}>
        <ThumbnailWrapper>
          {isPreviewVideoShowing && (
            <Div
              position="absolute"
              height="100%"
              top={0}
              right={0}
              pr={CARD_PREVIEW_PADDING_RIGHT}
              width="100%"
            >
              <Div position="absolute" height="100%" width={VIDEO_WIDTH}>
                {previewVideoUrl && (
                  <ResponsiveVideoPlayer
                    position="absolute"
                    playing
                    playsinline
                    controls={false}
                    volume={1}
                    muted={!settingsVolume}
                    url={previewVideoUrl}
                    onProgress={videoProgress => {
                      dispatch(
                        userPreviewingContentAction({
                          contentData,
                          component: selectedFrom,
                          previewDuration: Math.floor(
                            videoProgress.playedSeconds
                          ),
                        })
                      );
                    }}
                    loop
                    showLoader={false}
                    height="100%"
                    width="auto"
                  />
                )}
              </Div>
            </Div>
          )}
          <FadeOutThumbnail
            unmountOnExit
            timeout={500}
            in={!isPreviewVideoShowing}
          >
            <Thumbnail
              width="auto"
              height="100%"
              placeholderSrc={thumbnailPlaceholderSrc}
              src={thumbnailSrc}
            />
          </FadeOutThumbnail>
          <OverlayShadow height={isProgressCompleted ? "75%" : "50%"} />
          <VideoDetails isPreviewVideoShowing={isPreviewVideoShowing}>
            {isMoreDetailsShowing ? (
              <Flex
                flexDirection="column"
                alignItems="flex-end"
                height="100%"
                width="100%"
                justifyContent="flex-end"
                mb="16px"
              >
                <HeartIconButton
                  height="16px"
                  width="16px"
                  mb="8px"
                  isActive={isSaved}
                  onClick={e => {
                    e.preventDefault();
                    saveUserContent();
                  }}
                />
                <VolumeIconButton
                  height="16px"
                  width="16px"
                  mb="8px"
                  isActive={Boolean(settingsVolume)}
                  onClick={e => {
                    e.preventDefault();

                    dispatch(
                      toggleSettingsVolumeAction(settingsVolume ? 0 : 1)
                    );
                  }}
                />
                <Button
                  height="19px"
                  width="55px"
                  p={0}
                  mb="6px"
                  onClick={handleButtonClick}
                >
                  <Flex>
                    <H7 pt="1px">
                      {contentData.completed_classes ? "Resume" : "Start"}
                    </H7>
                  </Flex>
                </Button>
              </Flex>
            ) : (
              <Flex
                width="100%"
                height="100%"
                justifyContent="flex-end"
                flexDirection="column"
                alignContent="flex-end"
              >
                <Flex>
                  {contentData.isFree === false && !isSubscriptionActive && (
                    <Div
                      backgroundColor="monochrome.0"
                      borderRadius="2px"
                      width="16px"
                      height="16px"
                      mr="8px"
                    >
                      <Icon as={LockedIndicatorBlack} />
                    </Div>
                  )}
                  {!isMoreDetailsShowing && isProgressCompleted && (
                    <Div
                      backgroundColor="monochrome.10"
                      borderRadius="2px"
                      width="16px"
                      height="16px"
                      mr="8px"
                    >
                      <Icon
                        color="#81F1AE"
                        as={CheckSquareV2}
                        width="16px"
                        height="16px"
                      />
                    </Div>
                  )}
                  <ClassTypeLabel variant={contentData.level} mr="8px" mb="8px">
                    <H7>{contentData.level}</H7>
                  </ClassTypeLabel>
                  <ClassCountLabel>
                    <Icon
                      color="white"
                      width="12px"
                      height="11px"
                      as={Classes}
                      mr="7px"
                    />
                    {totalClassCount} Classes
                  </ClassCountLabel>
                </Flex>
                <Title>{contentData.title}</Title>
                <Flex justifyContent="flex-start" mb="6px">
                  {contentData.style !== undefined && (
                    <>
                      {instructorName ? (
                        <Text fontSize="10px" limitLines="2">
                          {contentData.style}
                          &nbsp;&nbsp;&bull;&nbsp;&nbsp;
                          {instructorName}
                        </Text>
                      ) : (
                        <Text fontSize="10px">{contentData.style}</Text>
                      )}
                    </>
                  )}
                </Flex>
              </Flex>
            )}
          </VideoDetails>
          <StyledProgressBar
            percent={progressPercent}
            hide={!progressPercent}
          />
          {!published && (
            <LockOverlay>
              <Icon width="20px" as={Lock} />
              <p>
                Available&nbsp;
                {moment(contentData.publish_date || contentData.publishDate)
                  .tz("America/Los_Angeles")
                  .format("MMM D ha z")}
              </p>
            </LockOverlay>
          )}
        </ThumbnailWrapper>
      </Container>
    </Wrapper>
  );
};

Card.defaultProps = {
  isProgressCompleted: null,
  isSaved: false,
  totalClassCount: 0,
};

Card.propTypes = {
  contentData: PropTypes.shape({}).isRequired,
  goToContent: PropTypes.func.isRequired,
  isProgressCompleted: PropTypes.bool,
  isSaved: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  saveUserContent: PropTypes.func.isRequired,
  selectedFrom: PropTypes.string.isRequired,
  totalClassCount: PropTypes.number,
  thumbnailPlaceholderSrc: PropTypes.string.isRequired,
  thumbnailSrc: PropTypes.string.isRequired,
  disableClick: PropTypes.bool,
};

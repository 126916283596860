/* eslint-disable camelcase */
import React from "react";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { SkeletonText, SkeletonTextLoader } from "app/components/SkeletonText";
import { SkeletonDivAnimation } from "app/components/SkeletonAnimation";

export const ProgramCardSkeleton = () => {
  return (
    <Flex
      width="280px"
      height="368px"
      backgroundColor="monochrome.11"
      borderRadius="8px"
      overflow="hidden"
      position="relative"
      zIndex="-1"
    >
      <Div zIndex="1" position="absolute" width="100%" height="100%">
        <SkeletonDivAnimation />
      </Div>
      <Flex
        width="280px"
        height="368px"
        borderRadius="8px"
        justifyContent="flex-end"
        flexDirection="column"
        alignContent="flex-end"
        padding="24px 24px"
        overflow="hidden"
        position="absolute"
        zIndex="2"
      >
        <SkeletonText height="16px" width="55px" mb="16px">
          <SkeletonTextLoader width="280px" height="368px" />
        </SkeletonText>
        <SkeletonText height="16px" width="166px" mb="18px">
          <SkeletonTextLoader width="280px" height="368px" />
        </SkeletonText>
        <Flex>
          <SkeletonText height="12px" width="82px" mr="16px">
            <SkeletonTextLoader width="280px" height="368px" />
          </SkeletonText>
          <SkeletonText height="12px" width="82px">
            <SkeletonTextLoader width="280px" height="368px" />
          </SkeletonText>
        </Flex>
      </Flex>
    </Flex>
  );
};

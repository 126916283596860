import styled from "styled-components";
import { variant } from "styled-system";
import Div from "app/components/Div";
import Flex from "app/components/Flex";
import { H2 } from "app/components/Typography";
import classLabelVariants from "theme/components/classLabelVariants";

export const Label = styled(Flex).attrs({
  borderRadius: "2px",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  backgroundColor: "monochrome.7",
  padding: "2px 5px",
  textTransform: "uppercase",
  height: "16px",
  fontSize: "9px",
})``;

export const ClassCountLabel = styled(Label)`
  font-size: 9px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const ClassTypeLabel = styled(Label)(
  variant({
    variants: classLabelVariants,
  })
);

export const Title = styled(H2)`
  overflow: hidden;
  line-height: 1.3em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 260px;
  margin-bottom: 8px;

  ${({ theme }) => theme.mediaQueries.ltsm} {
    width: 175px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const ThumbnailWrapper = styled(Div).attrs({
  height: "100%",
  position: "relative",
  overflow: "hidden",
})`
  border-radius: 8px;
  mask-image: -webkit-radial-gradient(white, black);
`;

export const OverlayShadow = styled(Div).attrs(({ height }) => ({
  position: "absolute",
  left: 0,
  bottom: 0,
  width: "100%",
  height: height || "50%",
}))``;

const textGradientBackground =
  "linear-gradient(rgba(0,0,0,0) 0%,rgba(34,34,34,0.2) 10%,rgba(34,34,34,0.4) 20%,rgba(34,34,34,0.6) 50%,rgb(34,34,34,0.75) 60%,rgb(34,34,34,0.9) 70%,rgb(34,34,34) 100%)";
const iconGradientBackground =
  "linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(34, 34, 34, 0.2) 50%, rgb(34, 34, 34, 0.6) 90%, rgb(34, 34, 34) 100%);";

export const VideoDetails = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  color: ${({ theme }) => theme.colors.white};
  padding: 10px 16px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  font-size: 11px;
  border-radius: 8px;
  background: ${({ isPreviewVideoShowing }) =>
    isPreviewVideoShowing ? iconGradientBackground : textGradientBackground};

  a {
    color: ${({ theme }) => theme.colors.white};
  }
`;
